import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from "@angular/router";
import { AlertController, LoadingController, NavController, MenuController, ToastController } from '@ionic/angular';
import { StorageService } from './services/storage.service';
import { GlobalEventService } from './globalservice';
import OneSignal from 'onesignal-cordova-plugin';
import * as firebase from 'firebase';
import { ServiceforallService } from './serviceforall.service';
import { AlertControllerService } from './services/alert-controller.service';

import { IpaService } from "./services/ipa.service";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent { 
  user_image: any;
  user_name: any;
  user_data: any;
  is_login: boolean = false;
  deviceToken:any;
  appPages: Array<{ title: string, url: any, img: any }> = [];
  constructor(
    private ipaService: IpaService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public alertController: AlertController,
    public alertService:AlertControllerService,
    private storageService: StorageService,
    public menu: MenuController,
    public navCtrl: NavController,
    public toastCtrl: ToastController,

    private globalEventService: GlobalEventService,
    public serviceforallService: ServiceforallService,
  ) {
    this.initializeApp();
  }







  initializeApp() {
    let _self = this;
    this.platform.ready().then(async () => {
      //this.statusBar.styleDefault();
      this.statusBar.styleBlackOpaque();
      let isapp:any = (document.URL.startsWith('https://web.roostercrm.com')|| document.URL.startsWith('https://web.roostercrm.com'));
      if(isapp){
        console.log('pwa');
      }else{
      if (this.platform.is('cordova')) {
          this.OneSignalInit();
      }
    }


    
      this.splashScreen.hide();

      var firebaseConfig = {
        apiKey: "AIzaSyDZpJuLvpNNTXazCW1FL6UhiO-c5svHLjo",
        authDomain: "rooster-crm.firebaseapp.com",
        databaseURL: "https://rooster-crm.firebaseio.com",
        projectId: "rooster-crm",
        storageBucket: "rooster-crm.appspot.com",
        messagingSenderId: "786460891172",
        appId: "1:786460891172:web:91368c7b0adaf33e91a0b1"
      };
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);

      this.ipaService.products = [];
      console.log("ipa products", this.ipaService.products)
  
  
      // ----new chanhe on 27oct
      await this.ipaService.initialize();


     this.storageService.getFromNativeStorage('user').then((val:any) => {
      val = JSON.parse(val);
        if (val != null) {
          this.is_login = true;
          this.menu.enable(true);
          console.log(" USER LOGIN " + JSON.stringify(val));
          this.user_data = val;
           this.storageService.removeFromNativeStorage('user_image');
           this.storageService.removeFromNativeStorage('user_name');
           this.storageService.removeFromNativeStorage('user_email');
           this.storageService.removeFromNativeStorage('is_paid');
          this.storageService.saveToNativeStorage('user_image', this.user_data.user_avatar);
          this.storageService.saveToNativeStorage('user_name', this.user_data.user_display_name);
          this.storageService.saveToNativeStorage('user_email', this.user_data.user_email);

          let menudata = {
            user_image: val.user_avatar,
            user_name: val.user_display_name,
            user_email: val.user_email
          }
          this.user_image = val.user_avatar;
          this.user_name = val.user_display_name;
          this.globalEventService.publishSomeData({
            type: 'loading_menu',
            menu: menudata
          });
          if (val.role == "saleperson") {
            this.appPages = [
              {
                title: 'Home / Messages',
                url: '/tabs/home',
                img: 'assets/home.png'
              },
              {
                title: 'Preset Messages',
                url: 'holiday-messages',
                img: 'assets/message.png'
              },
              {
                title: 'Clients',
                url: '/tabs/clients',
                img: 'assets/clients.png'
              },
              {
                title: 'Vendors',
                url: '/tabs/vendors',
                img: 'assets/vendor.png'
              },
              {
                title: 'Profile',
                url: '/tabs/profile',
                img: 'assets/m5.png'
              },
              {
                title: 'Settings',
                url: '/settings',
                img: 'assets/setting.png'
              },
              // {
              //   title: 'Message setting',
              //   url: '/schedule-message',
              //   img: 'assets/setting.png'
              // },
              {
                title: 'My Calendar',
                url: '/calendar',
                img: 'assets/calc.png'
              }
            ];
          } else {
            if (val.role == "client") {
              this.appPages = [
                {
                  title: 'Home/Sales rep',
                  url: '/tabs/home',
                  img: 'assets/home.png'
                },
                {
                  title: 'My Profile',
                  url: '/tabs/profile',
                  img: 'assets/m5.png'
                },
                {
                  title: 'Settings',
                  url: '/settings',
                  img: 'assets/setting.png'
                },
              ];
            } else {
              if (val.role == "vendor") {
                this.appPages = [
                  {
                    title: 'Home',
                    url: '/tabs/home',
                    img: 'assets/home.png'
                  },
                  {
                    title: 'My Profile',
                    url: '/tabs/profile',
                    img: 'assets/m5.png'
                  },
                  {
                    title: 'Settings',
                    url: '/settings',
                    img: 'assets/setting.png'
                  },
                ];
              }
            }
          }

          this.globalEventService.publishSomeData({
            type: 'userchecklogin',
            role: val.role
          });
          // this.navCtrl.navigateRoot('/tabs/home');

          console.log('loging user true');
        } else {
          this.storageService.clearNativeStorage();
          this.is_login = false;
          this.menu.enable(false);
          this.globalEventService.publishSomeData({
            type: 'userchecklogin',
            role: 'none'
          });
          console.log(this.router.url); 
          //this.router.navigate(['/login']);
        }
      });

      this.globalEventService.getObservable().subscribe((data) => {
        console.log(data);
        if (data.type == "loading_menu") {
          this.user_image = data.menu.user_image;
          this.user_name = data.menu.user_name;
        }
        if(data.type == "check_membership"){
           this.serviceforallService.has_plan=data.has_plan;
        }
      });

      this.globalEventService.getObservable().subscribe((data) => {
        if(data.type == "check_membership"){
          this.serviceforallService.has_plan=data.has_plan;
       }
        if (data.type == "userchecklogin") {
          this.is_login = true;
          this.menu.enable(true);
          if (data.role == "saleperson") {
            this.appPages = [
              {
                title: 'Home / Messages',
                url: '/tabs/home',
                img: 'assets/home.png'
              },
              {
                title: 'Preset Messages',
                url: 'holiday-messages',
                img: 'assets/message.png'
              },
              {
                title: 'Clients',
                url: '/tabs/clients',
                img: 'assets/clients.png'
              },
              {
                title: 'Vendors',
                url: '/tabs/vendors',
                img: 'assets/vendor.png'
              },
              {
                title: 'Profile',
                url: '/tabs/profile',
                img: 'assets/m5.png'
              },
              {
                title: 'Settings',
                url: '/settings',
                img: 'assets/setting.png'
              },
              // {
              //   title: 'Message setting',
              //   url: '/schedule-message',
              //   img: 'assets/setting.png'
              // },
              {
                title: 'Notifications',
                url: '/notifications',
                img: 'assets/notification_menu.png'
              },
              {
                title: 'My Calendar',
                url: '/calendar',
                img: 'assets/calc.png'
              }
            ];
          } else {
            if (data.role == "client") {
              this.appPages = [
                {
                  title: 'Home/Sales rep',
                  url: '/tabs/home',
                  img: 'assets/home.png'
                },
                {
                  title: 'My Profile',
                  url: '/tabs/profile',
                  img: 'assets/m5.png'
                },
                {
                  title: 'Settings',
                  url: '/settings',
                  img: 'assets/setting.png'
                },
                {
                  title: 'Notifications',
                  url: '/notifications',
                  img: 'assets/notification_menu.png'
                },
                {
                  title: 'My Calendar',
                  url: '/calendar',
                  img: 'assets/calc.png'
                }
              ];
            } else {
              if (data.role == "vendor") {
                this.appPages = [
                  {
                    title: 'Home',
                    url: '/tabs/home',
                    img: 'assets/home.png'
                  },
                  {
                    title: 'My Profile',
                    url: '/tabs/profile',
                    img: 'assets/m5.png'
                  },
                  {
                    title: 'Settings',
                    url: '/settings',
                    img: 'assets/setting.png'
                  },

                ];
              }
            }
          }

          if (data.role == 'none') {
            this.is_login = false;
            this.menu.enable(false);
          }
        }

      });
    });

  }

  async DoLogout() {
    const alert = await this.alertController.create({
      header: 'Rooster',
      message: 'Are you sure ?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.storageService.getFromNativeStorage('user').then((val:any) => {
              val = JSON.parse(val);
              if (val != null) {
                let user_id = val.user_id;
                // this.removeToken(user_id);
              }
            });
            this.is_login = false;
            this.menu.enable(false);
         //   this.serviceforallService.firebaseSignOut();
            this.storageService.clearNativeStorage();
            this.globalEventService.publishSomeData({
              type: 'userchecklogin',
              role: "none"
            });
            this.menu.close();
            this.navCtrl.navigateRoot('/login');
          }
        }
      ]
    });
    await alert.present();
  }

  openEditPro(){
    this.menu.close();
    this.navCtrl.navigateForward('/edit-profile');
  }


  OneSignalInit() {
    var self = this;
    console.log("OneSignalInit");
    OneSignal.setAppId("de749d53-38d4-496b-9872-de4963154dfa");
    OneSignal.setNotificationOpenedHandler( (jsonData: any)=> {
      this.onPushOpened(jsonData.notification)
    });

    OneSignal.setNotificationWillShowInForegroundHandler( (notificationReceivedEvent: any)=> {
        console.log('Notification received',notificationReceivedEvent.notification.body);
        notificationReceivedEvent.complete(notificationReceivedEvent.notification);
        this.alertService.presentAlert(notificationReceivedEvent.notification.body);
        // this.events.publish('refresh_req',true);
        
    });

    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted: any) {
        console.log("User accepted notifications: " + accepted);
    });

    setTimeout(() => {
        OneSignal.getDeviceState(res => {
            console.log("res: ", res);
            let temp: any = res;
            this.deviceToken = temp.userId;
            console.log(this.deviceToken);
        });
    }, 2000);
}

  private onPushReceived(payload:any) {
    console.log('Push recevied:', JSON.stringify(payload.additionalData));
  }




   onPushOpened(payload: any) {

    this.storageService.getFromNativeStorage('user').then((userInfo:any) => {
      if (userInfo != null) {
        //let notification_info = payload.additionalData.notification_info;
        let event_notification = payload.additionalData;
        if (event_notification.type == "appointment_complete" || event_notification.type == "appointment_start" || event_notification.type == "appointment_request") {
          this.router.navigate(['/booking2/' + event_notification.booking_id]);
        } else {
          this.router.navigate(['/tabs/shop']);
        }
      } else {
        this.router.navigate(['/home']);
      }

    }, error => {
      this.router.navigate(['/home']);
    });




  }
}
